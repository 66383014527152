import React from "react";
import Layout from "../../other/layout";
import Demarrez from "../../components/AC-StaticPages/Demarrez/Demarrez";
import SEO from "../../other/seo";

export default function demarrez() {
  return (
    <Layout>
      <SEO title="Démarrez Votre Enterprise" />

      <Demarrez />
    </Layout>
  );
}
