import React from "react";
import { Link } from "gatsby";
import CategoryHeader from "../../AC-CategoryPage/components/CategoryHeader/CategoryHeader";
import styles from "./styles.module.css";
import Button from "../components/Button";
import buttons from "../components/button.module.css";

export default function Demarrez() {
  const btnMargin = "10px auto";
  const minWidth = "0";
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Démarrez Votre Enterprise</h1>
        <p>
          Démarrer une nouvelle entreprise est une tâche d'envergure. Connaître
          les bonnes étapes à suivre conférera à votre entreprise de bien
          meilleures chances de réussite. Il y a bien des choses à prendre en
          compte lorsqu'on démarre une entreprise. NEBS dispose de toutes sortes
          de renseignements et des outils dont vous avez besoin pour mettre
          votre entreprise sur pied et l'exploiter avec succès!
        </p>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2 style={{ color: "unset", fontSize: "1.5em" }}>
              Enregistrement d'entreprise
            </h2>
            <p>
              Deluxe offre maintenant aux propriétaires de petits commerces
              canadiens des services d'enregistrement d'entreprise en ligne à
              une fraction du prix demandé par les fournisseurs traditionnels.
            </p>

            <a
              style={{ minWidth: minWidth, margin: btnMargin }}
              href="https://www.nebscorp.ca/docfr/pbr/home.asp?d=brQuote"
              target="_blank"
              className={buttons.link}
            >
              Enregistrement d'entreprise (s'ouvre dans une nouvelle fenètre)
            </a>
            <a
              style={{ minWidth: minWidth, margin: btnMargin }}
              href="http://www.nebscorp.ca/docfr/pns/home.asp"
              target="_blank"
              className={buttons.link}
            >
              Rapports de recherche de noms (s'ouvre dans une nouvelle fenètre)
            </a>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2 style={{ color: "unset", fontSize: "1.5em" }}>
              Imprimés d'affaires
            </h2>
            <p>
              Deluxe propose une gamme complète de produits d'affaires imprimés
              qui faciliteront le démarrage de votre entreprise.
            </p>
            <Button
              text={"Chèques"}
              minWidth={minWidth}
              margin={btnMargin}
              url={"/gerez/cheques/"}
            />
            <Button
              text={"Papeterie"}
              minWidth={minWidth}
              margin={btnMargin}
              url={"/gerez/papeterie/"}
            />
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2 style={{ color: "unset", fontSize: "1.5em" }}>
              Conception de logo
            </h2>
            <p>
              Besoin d'un logo ? Donnez une allure professionnelle à votre
              nouvelle petite entreprise. Nos concepteurs chevronnés
              travailleront directement avec vous pour créer un logo unique qui
              fonctionnera pour votre commerce.
            </p>
            <Button
              text={"Conception de logo"}
              minWidth={minWidth}
              margin={btnMargin}
              url={"/demarrez/conception-de-logo/"}
            />
            <Button
              text={"Comment cela fonctionne"}
              minWidth={minWidth}
              margin={btnMargin}
              url={"/demarrez/conception-de-logo/comment-cela-fonctionne/"}
            />
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2 style={{ color: "unset", fontSize: "1.5em" }}>
              Services en ligne
            </h2>
            <p>
              Deluxe offre maintenant aux propriétaires de petits commerces
              canadiens des services d'enregistrement d'entreprise en ligne à
              une fraction du prix demandé par les fournisseurs traditionnels
            </p>
            <Button
              text={"Noms de domaine"}
              minWidth={minWidth}
              margin={btnMargin}
              url={"/demarrez/noms-de-domaine/"}
            />
            <Button
              text={"Forfaits courriels"}
              minWidth={minWidth}
              margin={btnMargin}
              url={"/developpez/services-web/forfaits-courriels/"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
